.btn-lg {
    --bs-btn-padding-y: 0.5rem !important;
    --bs-btn-padding-x: 3rem !important;
    --bs-btn-font-size: 1.2rem !important;
    --bs-btn-border-radius: 0.5rem !important;
}

.logo {
    display: block;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.card {
    background-color: #ffffff;
    border-radius: 3px;
   /* padding: 25px 40px; */
    box-shadow: rgba(0,0,0,0.1) 0 0 10px;
}

.form-control-lg {
    font-size: 14px;
    background-color: #fafbfc;
    border: 2px solid #dfe1e6;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    height: 40px;
    -webkit-transition: background-color .2s ease-in-out 0s,border-color .2s ease-in-out 0s;
    transition: background-color .2s ease-in-out 0s,border-color .2s ease-in-out 0s;
}

.login-method-separator {
    text-align: center;
    font-size: 12px;
    line-height: 1;
    margin-top: 16px;
    margin-bottom: 16px;
}

.oauth-button {
    background: #fff;
    color: rgba(0,0,0,0.54);
    box-shadow: rgba(0,0,0,0.2) 1px 1px 5px 0;
    border-color: transparent;
    text-align: center;
    border-radius: 3px;
    width: 99%;
    height: 50px;
    padding-top: 0;
    margin-bottom: 12px;
    display: block;
    text-decoration: none;
    -webkit-transition: background-color .2s ease-in-out 0s,border-color .2s ease-in-out 0s;
    transition: background-color .2s ease-in-out 0s,border-color .2s ease-in-out 0s;
}


.icon {
    display: inline-block;
    border-radius: 1px;
    width: 20px;
    height: 40px;
    vertical-align: -webkit-baseline-middle;
    
}
#google-icon {
    background: url(https://d2k1ftgv7pobq7.cloudfront.net/meta/c/p/res/images/8215f6659adc202403198fef903a447e/sign-in-with-google.svg) 0 50% no-repeat;
}
#ms-icon {
    background: url(https://d2k1ftgv7pobq7.cloudfront.net/meta/c/p/res/images/72ece804e5285ab6507e2406157cda3c/microsoft-logo.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.responsiveDesktop {
    width: 465px;
}

@media screen and (max-width: 560px) {
    .responsiveDesktop{
        width:unset;    }
}

.label {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-family: inherit;
    font-weight: bold;
    color: #505f79;
    padding: 3px;
    height: 32px;
    line-height: 32px;
}

.h1Login {
    text-align: center;
    color: #5e6c84;
    font-size: 20px;
    letter-spacing: -0.01em;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 25px;
}

body {
    background-color: #f9fafc;
    font-family: '-apple-system',BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 14px;
}

.input-container {
    position: relative;
    display: block;
}
  
.edit-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
/*
a:hover {
    background-color: aliceblue !important;
}*/

#googleButton{
    cursor:pointer;
}
  
  
  